import React, { useState, useEffect } from "react";
import Axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import AutoSelect from "./InputComponents/AutoSelect";
import "./index.css";
import axios from "axios";
import UrlLinks from "../../../UrlLinks";
import { Link, useHistory, useParams } from "react-router-dom";
import styles from "./index.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import { AppData } from "../../../context/context";
import { useContext } from "react";

const useStyles = makeStyles({
  clientName: {
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#1C75BC",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #1C75BC",
      },
    },
  },
});

const EligibilityCriteriaForm = (data) => {
  const classes = useStyles();
  const history = useHistory();
  const [laoding, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [formData, setFormData] = useState({
    fromAge: "",
    toAge: "",
    projectName: "",
    jobTitle: "",
    jobType: "",
    jobTypeLocation: "",
    jobDesc: "",
    locationType: "",
    gender: {},
    education: {},
    experience: {},
    assets: [],
    documents: [],
    language: [],
    skills: [],
    mustHaveAge: null,
    mustHaveGender: null,
    mustHaveEducation: null,
    mustHaveAssets: null,
    mustHaveLanguage: null,
  });

  const { formMode, setFormMode, jobConfigMode, eligibilityJobId } =
    useContext(AppData);
  const [initialProjectKey, setInitialProjectKey] = useState("");
  const [fieldEmptyCheck, setFieldEmptyCheck] = useState(false);
  const [apiRes, setApiRes] = useState({});
  const [mustHaveAge, setMustHaveAge] = useState(null);

  const handleMustHaveAgeToggle = () => {
    setMustHaveAge((prev) => (prev === null ? true : !prev));
    setFormData((prev) => ({
      ...prev,
      mustHaveAge: mustHaveAge === null ? true : !mustHaveAge,
    }));
  };

  const [mustHaveGender, setMustHaveGender] = useState(null);
  const handleMustHaveGenderToggle = () => {
    setMustHaveGender((prev) => (prev === null ? true : !prev));
    setFormData((prev) => ({
      ...prev,
      mustHaveGender: mustHaveGender === null ? true : !mustHaveGender,
    }));
  };

  const [mustHaveEducation, setMustHaveEducation] = useState(null);
  const handleMustHaveEducationToggle = () => {
    setMustHaveEducation((prev) => (prev === null ? true : !prev));
    setFormData((prev) => ({
      ...prev,
      mustHaveEducation: mustHaveEducation === null ? true : !mustHaveEducation,
    }));
  };

  const [mustHaveAssets, setMustHaveAssets] = useState(null);
  const handleMustHaveAssetsToggle = () => {
    setMustHaveAssets((prev) => (prev === null ? true : !prev));
    setFormData((prev) => ({
      ...prev,
      mustHaveAssets: mustHaveAssets === null ? true : !mustHaveAssets,
    }));
  };

  const [mustHaveLanguage, setMustHaveLanguage] = useState(null);
  const handleMustHaveLanguageToggle = () => {
    setMustHaveLanguage((prev) => (prev === null ? true : !prev));
    setFormData((prev) => ({
      ...prev,
      mustHaveLanguage: mustHaveLanguage === null ? true : !mustHaveLanguage,
    }));
  };

  const [optionList, setOptionList] = useState({
    educationList: [],
    assetsList: [],
    genderList: [],
    documentList: [],
    languageList: [],
    experienceList: [],
    skillsList: [],
  });
  const { jobId } = useParams();

  const makeShiftList = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.id,
        name: el.typeValue,
      });
      return true;
    });
    return list;
  };

  const validateFormData = (formData) => {
    // Loop through the keys in the formData object
    for (const key in formData) {
      if (key === "description") continue;
      if (key === "is_has_job") continue;
      if (!formData[key]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setFieldEmptyCheck(!validateFormData(formData));
  }, [formData]);

  useEffect(() => {
    let currentFormMode = "create";
    window.scrollTo(0, 0);

    Axios.get(`${UrlLinks.baseUrl}task-job/jobConfigValues`).then(
      (response) => {
        setOptionList((prev) => ({
          ...prev,
          assetsList: makeShiftList(response.data.assets),
          educationList: makeShiftList(response.data.education),
          skillsList: makeShiftList(response.data.skills),
          genderList: makeShiftList(response.data.gender),
          documentList: makeShiftList(response.data.document),
          languageList: makeShiftList(response.data.language),
          experienceList: makeShiftList(response.data.experience),
        }));
      }
    );

    if (jobId !== "add") {
      Axios.get(`${UrlLinks.baseUrl}task-job/${jobId}`).then((response) => {
        const newEligibilityData = response.data.page2
          ? JSON.parse(response.data.page2)
          : { ...formData };
        setFormData(newEligibilityData);
        setApiRes(response.data);
        setMustHaveAge((prev) => {
          if (response.data.ageMust === 1) {
            return true;
          } else if (response.data.ageMust === 0) {
            return false;
          } else if (response.data.ageMust === null) {
            return null;
          } else {
            return prev;
          }
        });

        setMustHaveGender((prev) => {
          if (response.data.genderMust === 1) {
            return true;
          } else if (response.data.genderMust === 0) {
            return false;
          } else if (response.data.genderMust === null) {
            return null;
          } else {
            return prev;
          }
        });

        setMustHaveLanguage((prev) => {
          if (response.data.langMust === 1) {
            return true;
          } else if (response.data.langMust === 0) {
            return false;
          } else if (response.data.langMust === null) {
            return null;
          } else {
            return prev;
          }
        });

        setMustHaveEducation((prev) => {
          if (response.data.educationMust === 1) {
            return true;
          } else if (response.data.educationMust === 0) {
            return false;
          } else if (response.data.educationMust === null) {
            return null;
          } else {
            return prev;
          }
        });

        setMustHaveAssets((prev) => {
          if (response.data.assetMust === 1) {
            return true;
          } else if (response.data.assetMust === 0) {
            return false;
          } else if (response.data.assetMust === null) {
            return null;
          } else {
            return prev;
          }
        });
      });
    }

    setFormMode(currentFormMode);
  }, []);

  useEffect(() => {
    if (mustHaveAge === null && apiRes.ageMust === null) {
      if (formData.fromAge !== "" || formData.toAge !== "") {
        let updatedMustHaveAge = true;
        setMustHaveAge(updatedMustHaveAge);
        setFormData((prev) => ({
          ...prev,
          mustHaveAge: updatedMustHaveAge,
        }));
      }
    }

    if (mustHaveGender === null && apiRes.genderMust === null) {
      if (Object.keys(formData.gender).length !== 0) {
        let updatedMustHaveGender = true;
        setMustHaveGender(updatedMustHaveGender);
        setFormData((prev) => ({
          ...prev,
          mustHaveGender: updatedMustHaveGender,
        }));
      }
    }

    if (mustHaveEducation === null && apiRes.educationMust === null) {
      if (Object.keys(formData.education).length !== 0) {
        let updatedMustHaveEducation = true;
        setMustHaveEducation(updatedMustHaveEducation);
        setFormData((prev) => ({
          ...prev,
          mustHaveEducation: updatedMustHaveEducation,
        }));
      }
    }

    if (mustHaveAssets === null && apiRes.assetMust === null) {
      if (Object.keys(formData.assets).length !== 0) {
        let updatedMustHaveAsset = true;
        setMustHaveAssets(updatedMustHaveAsset);
        setFormData((prev) => ({
          ...prev,
          mustHaveAssets: updatedMustHaveAsset,
        }));
      }
    }

    if (mustHaveLanguage === null && apiRes.langMust === null) {
      if (Object.keys(formData.language).length !== 0) {
        let updatedMustHaveLanguage = true;
        setMustHaveLanguage(updatedMustHaveLanguage);
        setFormData((prev) => ({
          ...prev,
          mustHaveLanguage: updatedMustHaveLanguage,
        }));
      }
    }
  }, [formData]);

  useEffect(() => {
    setInitialProjectKey(formData.projectKey);
  }, [formMode]);

  const handleSaveBtn = () => {
    setError({}); // Clear previous errors

    let hasError = false;

    if (formData.mustHaveAge) {
      if (!formData.fromAge) {
        setError((prev) => ({ ...prev, ageError: "From Age is required" }));
        hasError = true;
      }
      if (!formData.toAge) {
        setError((prev) => ({ ...prev, ageError: "To Age is required" }));
        hasError = true;
      }
      if (Number(formData.fromAge) < 18) {
        setError((prev) => ({
          ...prev,
          ageError: "From age should be greater than 18",
        }));
        hasError = true;
      }
    }

    if (formData.fromAge) {
      if (!formData.toAge) {
        setError((prev) => ({ ...prev, ageError: "To Age is required" }));
        hasError = true;
      }
    }

    if (formData.toAge) {
      if (!formData.fromAge) {
        setError((prev) => ({ ...prev, ageError: "From Age is required" }));
        hasError = true;
      }
    }

    if (
      formData.mustHaveGender &&
      (!formData.gender || Object.keys(formData.gender).length === 0)
    ) {
      setError((prev) => ({ ...prev, genderError: "This field is Required" }));
      hasError = true;
    }

    if (
      formData.mustHaveEducation &&
      (!formData.education || Object.keys(formData.education).length === 0)
    ) {
      setError((prev) => ({
        ...prev,
        educationError: "This field is Required",
      }));
      hasError = true;
    }

    if (
      formData.mustHaveAssets &&
      (!formData.assets || formData.assets.length === 0)
    ) {
      setError((prev) => ({ ...prev, assetError: "This field is Required" }));
      hasError = true;
    }

    if (
      formData.mustHaveLanguage &&
      (!formData.language || formData.language.length === 0)
    ) {
      setError((prev) => ({
        ...prev,
        languageError: "This field is Required",
      }));
      hasError = true;
    }

    if (hasError) return;

    try {
      const mainData = { ...formData };
      const formData1 = new FormData();

      const body = JSON.stringify({
        age_from: +mainData.fromAge,
        age_to: +mainData.toAge,
        assets: mainData.assets
          ? mainData.assets.map((obj) => obj.id).join(",")
          : "",
        documents: mainData.documents
          ? mainData.documents.map((obj) => obj.id).join(",")
          : "",
        education: mainData.education ? mainData.education.id : null,
        experience: mainData.experience ? mainData.experience.id : null,
        gender: mainData.gender ? mainData.gender.id : null,
        jobId: jobId !== "add" ? +jobId : +eligibilityJobId,
        language: mainData.language
          ? mainData.language.map((obj) => obj.id).join(",")
          : "",
        skills: mainData.skills
          ? mainData.skills.map((obj) => obj.id).join(",")
          : "",
        ageMust: mainData.mustHaveAge
          ? 1
          : mainData.mustHaveAge === false
          ? 0
          : null,
        genderMust: mainData.mustHaveGender
          ? 1
          : mainData.mustHaveGender === false
          ? 0
          : null,
        educationMust: mainData.mustHaveEducation
          ? 1
          : mainData.mustHaveEducation === false
          ? 0
          : null,
        langMust: mainData.mustHaveLanguage
          ? 1
          : mainData.mustHaveLanguage === false
          ? 0
          : null,
        assetsMust: mainData.mustHaveAssets
          ? 1
          : mainData.mustHaveAssets === false
          ? 0
          : null,
      });

      formData1.append("eligibilityBody", body);
      formData1.append("page2", JSON.stringify(mainData));

      const userId = localStorage.getItem("userID");

      axios
        .post(
          `${UrlLinks.baseUrl}task-job/addEditJob?insertedBy=${userId}`,
          formData1,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "*/*", // Update the accept header here
            },
          }
        )
        .then((res) => {
          history.push(`/jobsdb/screening/${jobId}`);
        });

      formData1.append("eligibilityBody", body);
      formData1.append("page2", JSON.stringify(mainData));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [assetInfoShow, setAssetInfoShow] = useState(false);
  const [langInfoShow, setLangInfoShow] = useState(false);
  const [eduInfoShow, setEduInfoShow] = useState(false);
  const [genderInfoShow, setGenderInfoShow] = useState(false);
  const [ageInfoShow, setAgeInfoShow] = useState(false);

  return (
    <>
      <div className="">
        <div className="">
          <div className={``}>
            <div className={`mainBoxWide`}>
              <div className={styles.BackNavigator}>
                <div>
                  <Link
                    to={`/jobsdb/jobConfig/${jobId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <ArrowBackIcon
                      sx={{
                        color: "black",
                        fontSize: "40",
                        marginBottom: "-5px",
                      }}
                      onClick={() => {
                        setFormMode("create");
                      }}
                    ></ArrowBackIcon>
                  </Link>
                  <span className={styles.formHeading}>
                    {jobConfigMode === "create"
                      ? "Add Job Eligibility Criteria 2/3"
                      : "Edit Job"}
                  </span>
                </div>
                {formMode === "view" && (
                  <div>
                    <button
                      className={styles.editBtn}
                      onClick={() => {
                        setFormMode("edit");
                      }}
                    >
                      {" "}
                      <EditIcon /> <span>Edit</span>{" "}
                    </button>
                  </div>
                )}
              </div>
              <br></br>
              <div className="fieldBox">
                <div className={`fieldInputs`}>
                  <span
                    className={`${styles.labelBox} ${styles.formFont} ${
                      formMode === "view" ? styles.lightText : ""
                    }`}
                  >
                    Age
                  </span>
                  {formMode === "view" ? (
                    <p>
                      From :{" "}
                      {formData && formData.fromAge ? formData.fromAge : ""} To
                      : {formData && formData.toAge ? formData.toAge : ""}
                    </p>
                  ) : (
                    <div className="ageInputs">
                      <div>
                        <label htmlFor="fromAge">From:</label>
                        <input
                          type="number"
                          id="fromAge"
                          name="fromAge"
                          value={formData.fromAge}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              fromAge: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div>
                        <label htmlFor="toAge">To:</label>
                        <input
                          type="number"
                          id="toAge"
                          name="toAge"
                          value={formData.toAge}
                          onChange={(e) =>
                            setFormData({ ...formData, toAge: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  )}
                  {!(mustHaveAge === null) && (
                    <div className="mustHaveToggleBtn">
                      <div
                        className="infoBtn"
                        onMouseLeave={() => setAgeInfoShow(false)}
                        onMouseEnter={() => setAgeInfoShow(true)}
                      >
                        Must Have :
                        {ageInfoShow && (
                          <div className="InfoBox">
                            <div className="infoArrow"></div>
                            <div className="InfoBox3rd">
                              &#40; user must answer this
                            </div>
                            <div>question correctly to qualify &#41;</div>
                          </div>
                        )}
                      </div>
                      <div
                        onClick={handleMustHaveAgeToggle}
                        className={`mustHave ${mustHaveAge ? "active" : ""}`}
                      >
                        <div
                          className={`mustHaveToggle ${
                            mustHaveAge ? "toggleActive" : ""
                          }`}
                        ></div>
                      </div>
                    </div>
                  )}
                  {error.ageError && <div></div>}
                  {error.ageError && (
                    <div className="ErrRed">{error.ageError}</div>
                  )}
                </div>
                <div className="horizontalRuleFull"></div>
                <div className={`fieldInputs`}>
                  <span
                    className={`${styles.labelBox} ${styles.formFont} ${
                      formMode === "view" ? styles.lightText : ""
                    }`}
                  >
                    Gender
                  </span>
                  {formMode === "view" ? (
                    <p>
                      {formData.gender && formData.gender.name
                        ? formData.gender.name
                        : ""}
                    </p>
                  ) : (
                    <AutoSelect
                      data={formData}
                      setData={setFormData}
                      id="gender"
                      multipleFlag={false}
                      label="Select Gender"
                      list={optionList.genderList}
                      disabled={
                        formData.is_has_job === 1 ? formMode === "edit" : ""
                      }
                    />
                  )}
                  {!(mustHaveGender === null) && (
                    <div className="mustHaveToggleBtn">
                      <div
                        className="infoBtn"
                        onMouseLeave={() => setGenderInfoShow(false)}
                        onMouseEnter={() => setGenderInfoShow(true)}
                      >
                        Must Have :
                        {genderInfoShow && (
                          <div className="InfoBox">
                            <div className="infoArrow"></div>
                            <div className="InfoBox3rd">
                              &#40; user must answer this
                            </div>
                            <div>question correctly to qualify &#41;</div>
                          </div>
                        )}
                      </div>
                      <div
                        onClick={handleMustHaveGenderToggle}
                        className={`mustHave ${mustHaveGender ? "active" : ""}`}
                      >
                        <div
                          className={`mustHaveToggle ${
                            mustHaveGender ? "toggleActive" : ""
                          }`}
                        ></div>
                      </div>
                    </div>
                  )}
                  {error.genderError && <div></div>}
                  {error.genderError && (
                    <div className="ErrRed">{error.genderError}</div>
                  )}
                </div>
                <div className="horizontalRuleFull"></div>
                <div className={`fieldInputs`}>
                  <span
                    className={`${styles.labelBox} ${styles.formFont} ${
                      formMode === "view" ? styles.lightText : ""
                    }`}
                  >
                    Education
                  </span>
                  {formMode === "view" ? (
                    <p>
                      {formData.education && formData.education.name
                        ? formData.education.name
                        : ""}
                    </p>
                  ) : (
                    <AutoSelect
                      data={formData}
                      setData={setFormData}
                      id="education"
                      multipleFlag={false}
                      label="Select Education"
                      list={optionList.educationList}
                      disabled={
                        formData.is_has_job === 1 ? formMode === "edit" : ""
                      }
                    />
                  )}
                  {!(mustHaveEducation === null) && (
                    <div className="mustHaveToggleBtn">
                      <div
                        className="infoBtn"
                        onMouseLeave={() => setEduInfoShow(false)}
                        onMouseEnter={() => setEduInfoShow(true)}
                      >
                        Must Have :
                        {eduInfoShow && (
                          <div className="InfoBox">
                            <div className="infoArrow"></div>
                            <div className="InfoBox3rd">
                              &#40; user must answer this
                            </div>
                            <div>question correctly to qualify &#41;</div>
                          </div>
                        )}
                      </div>
                      <div
                        onClick={handleMustHaveEducationToggle}
                        className={`mustHave ${
                          mustHaveEducation ? "active" : ""
                        }`}
                      >
                        <div
                          className={`mustHaveToggle ${
                            mustHaveEducation ? "toggleActive" : ""
                          }`}
                        ></div>
                      </div>
                    </div>
                  )}
                  {error.educationError && <div></div>}
                  {error.educationError && (
                    <div className="ErrRed">{error.educationError}</div>
                  )}
                </div>
                <div className="horizontalRuleFull"></div>
                <div className={`fieldInputs`}>
                  <span
                    className={`${styles.labelBox} ${styles.formFont} ${
                      formMode === "view" ? styles.lightText : ""
                    }`}
                  >
                    Assets
                  </span>
                  {formMode === "view" ? (
                    <p>
                      {formData.assets
                        ? formData.assets.map((obj) => obj.name).join(", ")
                        : ""}
                    </p>
                  ) : (
                    <AutoSelect
                      data={formData}
                      setData={setFormData}
                      id="assets"
                      multipleFlag={true}
                      label="Select Assets"
                      list={optionList.assetsList}
                      disabled={
                        formData.is_has_job === 1 ? formMode === "edit" : ""
                      }
                    />
                  )}
                  {!(mustHaveAssets === null) && (
                    <div className="mustHaveToggleBtn">
                      <div
                        className="infoBtn"
                        onMouseLeave={() => setAssetInfoShow(false)}
                        onMouseEnter={() => setAssetInfoShow(true)}
                      >
                        Must Have :
                        {assetInfoShow && (
                          <div className="InfoBox">
                            <div className="infoArrow"></div>
                            <div className="InfoBox3rd">
                              &#40; user must answer this
                            </div>
                            <div>question correctly to qualify &#41;</div>
                          </div>
                        )}
                      </div>
                      <div
                        onClick={handleMustHaveAssetsToggle}
                        className={`mustHave ${mustHaveAssets ? "active" : ""}`}
                      >
                        <div
                          className={`mustHaveToggle ${
                            mustHaveAssets ? "toggleActive" : ""
                          }`}
                        ></div>
                      </div>
                    </div>
                  )}
                  {error.assetError && <div></div>}
                  {error.assetError && (
                    <div className="ErrRed">{error.assetError}</div>
                  )}
                </div>
                <div className="horizontalRuleFull"></div>
                <div className={`fieldInputs`}>
                  <span
                    className={`${styles.labelBox} ${styles.formFont} ${
                      formMode === "view" ? styles.lightText : ""
                    }`}
                  >
                    Language
                  </span>
                  {formMode === "view" ? (
                    <p>
                      {formData.language
                        ? formData.language.map((obj) => obj.name).join(", ")
                        : ""}
                    </p>
                  ) : (
                    <AutoSelect
                      data={formData}
                      setData={setFormData}
                      id="language"
                      multipleFlag={true}
                      label="Select Language"
                      list={optionList.languageList}
                      disabled={
                        formData.is_has_job === 1 ? formMode === "edit" : ""
                      }
                    />
                  )}
                  {!(mustHaveLanguage === null) && (
                    <div className="mustHaveToggleBtn">
                      <div
                        className="infoBtn"
                        onMouseLeave={() => setLangInfoShow(false)}
                        onMouseEnter={() => setLangInfoShow(true)}
                      >
                        Must Have :
                        {langInfoShow && (
                          <div className="InfoBox">
                            <div className="infoArrow"></div>
                            <div className="InfoBox3rd">
                              &#40; user must answer this
                            </div>
                            <div>question correctly to qualify &#41;</div>
                          </div>
                        )}
                      </div>
                      <div
                        onClick={handleMustHaveLanguageToggle}
                        className={`mustHave ${
                          mustHaveLanguage ? "active" : ""
                        }`}
                      >
                        <div
                          className={`mustHaveToggle ${
                            mustHaveLanguage ? "toggleActive" : ""
                          }`}
                        ></div>
                      </div>
                    </div>
                  )}
                  {error.languageError && <div></div>}
                  {error.languageError && (
                    <div className="ErrRed">{error.languageError}</div>
                  )}
                </div>
                <div className="horizontalRuleFull"></div>
                <div className={`fieldInputs`}>
                  <span
                    className={`${styles.labelBox} ${styles.formFont} ${
                      formMode === "view" ? styles.lightText : ""
                    }`}
                  >
                    Documents
                  </span>
                  {formMode === "view" ? (
                    <p>
                      {formData.documents
                        ? formData.documents.map((obj) => obj.name).join(", ")
                        : ""}
                    </p>
                  ) : (
                    <AutoSelect
                      data={formData}
                      setData={setFormData}
                      id="documents"
                      multipleFlag={true}
                      label="Select Documents"
                      list={optionList.documentList}
                      disabled={
                        formData.is_has_job === 1 ? formMode === "edit" : ""
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            {formMode !== "view" && (
              <div
                className={styles.saveCancel}
                style={{
                  backgroundColor: "#fff",
                  marginTop: 0,
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                }}
              >
                <div className={styles.BtnBox}>
                  <Link to="/jobsdb" style={{ textDecoration: "none" }}>
                    {" "}
                    <Button
                      onClick={() => {
                        setFormMode("create");
                      }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>{" "}
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button onClick={handleSaveBtn} variant="contained">
                    Next
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={laoding}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};

export default EligibilityCriteriaForm;
