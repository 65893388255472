import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Button, Divider } from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MultipleSelectCheckmarksProps {
  updateMyData: () => void;
  setDisFilterUrl: (url: string) => void;
  setClearFilter: (value: boolean) => void;
  clearFilter: boolean;
}

const names = [
  "Shipment Qty Discrepancies",
  "Worker Payout Discrepancies",
  "Margin Discrepancies",
];

export default function MultipleSelectCheckmarks(props: MultipleSelectCheckmarksProps) {
  const { updateMyData, setDisFilterUrl, setClearFilter, clearFilter } = props;
  const [personName, setPersonName] = useState<string[]>([]);
  const [open, setOpen] = useState(false);

  const handleChangeTX = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    let disFilter = "";

    typeof value !== "string" &&
      value.map((e) => {
        switch (e) {
          case "Shipment Qty Discrepancies":
            disFilter += "&quantity_flag=1";
            break;
          case "Worker Payout Discrepancies":
            disFilter += "&amount_flag=1";
            break;
          case "Margin Discrepancies":
            disFilter += "&margin_flag=1";
            break;
          default:
            break;
        }
      });
    setDisFilterUrl(disFilter);
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearDiscrepanciesFilter = () => {
    setDisFilterUrl("");
    setPersonName([]);
    setClearFilter(!clearFilter);
  };

  return (
    <div>
      <FormControl sx={{ width: 300, height: 40 }}>
        <InputLabel id="demo-multiple-checkbox-label">
          Discrepancies Cases
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          style={{ height: "40px", marginTop: "5px" }}
          value={personName}
          onChange={handleChangeTX}
          input={<OutlinedInput label="Discrepancies Cases" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}

          <Divider style={{ margin: "15px" }}></Divider>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              disabled={personName.length === 0}
              onClick={() => {
                clearDiscrepanciesFilter();
              }}
            >
              {" "}
              Clear{" "}
            </Button>
            <Button
              variant="contained"
              className="dataButton"
              style={{ marginLeft: "15px", marginRight: "15px" }}
              disabled={personName.length === 0}
              onClick={() => {
                updateMyData();
                handleClose();
              }}
            >
              {" "}
              Apply{" "}
            </Button>
          </div>
        </Select>
      </FormControl>
    </div>
  );
}
