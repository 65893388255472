import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/actions/index";
import styles from "./Components.module.scss";
import Axios from "axios";
import { Button, Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DateFilter from "../../../Utils/FilterComponents/DateFilter";
import AutoSelectFilter from "../../../Utils/FilterComponents/AutoSelectFilter";
import ScaleLoader from "react-spinners/ScaleLoader";
import urlLinks from "../../../UrlLinks";
import MultipleSelectFilter from "../../../Utils/FilterComponents/MultipleSelectFilter";
import SearchSelectFilter from "../../../Utils/FilterComponents/SearchSelectFilter";

const FilterDrawer = (props) => {
  const {
    handleClose,
    resetFilters,
    applyFilters,
    filters,
    setFilters,
    leadCityId,
    setLeadCityId,
    leadSourceId,
    setLeadSourceId,
    preferredPincodes,
    setPreferredPincodes,
    preferredLanguages,
    setPreferredLanguages,
    assets,
    setAssets,
    recruiterList,
    unapplied,
    searchList,
  } = props;
  const { users, applicationDb } = useSelector((state) => state);
  const { modalLoader } = users;
  const {
    curLeadId,
    multiplePreferredPincodes,
    multiplePreferredLanguages,
    multipleAssets,
  } = applicationDb;

  const dispatch = useDispatch();
  const { setModalLoader, setCurLeadId } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const [leadState, setLeadState] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [preferredLanguagesList, setPreferredLanguagesList] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [qualificationsList, setQualificationsList] = useState([]);
  const [verticalList, setVerticalList] = useState([]);
  const [filterRecruiterList, setFilterRecruiterList] = useState(recruiterList);
  const [statusList, setStatusList] = useState([]);

  const handleCancelFilters = () => {
    setLeadCityId(curLeadId.leadCity);
    setLeadSourceId(curLeadId.leadSource);
    setPreferredPincodes(multiplePreferredPincodes);
    setPreferredLanguages(multiplePreferredLanguages);
    setAssets(multipleAssets);
    handleClose();
  };

  const handleApplyFilters = () => {
    setCurLeadId(
      leadCityId,
      leadSourceId,
      preferredPincodes,
      preferredLanguages,
      assets
    );
    applyFilters();
    handleClose();
  };

  const yesNo = [
    {
      id: 1,
      name: "Yes",
    },
    {
      id: 0,
      name: "No",
    },
  ];

  const recruiterStatusList = [
    {
      id: "Call Again",
      name: "Call Again",
    },
    {
      id: "Not Interested",
      name: "Not Interested",
    },
    {
      id: "Interview",
      name: "interview",
    },
  ];

  const makeLeadStateData = (data) => {
    let list = [];
    data.map((el) => {
      let array = el.typeValue.split(",");
      array.map((element, index) => {
        list.push({
          id: index,
          name: element,
        });
        return true;
      });
      return true;
    });
    return list;
  };

  const makeLeadSourceData = (data) => {
    let list = [];
    data.map((el, index) => {
      list.push({
        id: index,
        name: el,
        label: el,
      });
      return true;
    });
    return list;
  };

  const makeGenderData = (data) => {
    let list = [];
    data.map((el, index) => {
      list.push({
        id: el.id,
        name: el.typeValue,
      });
      return true;
    });
    return list;
  };

  const makePreferredLanguagesData = (data) => {
    let list = [];
    data.map((el, index) => {
      list.push({
        id: el.id,
        name: el.id,
        label: el.typeValue,
      });
      return true;
    });
    return list;
  };

  const makeAssetsData = (data) => {
    let list = [];
    data.map((el, index) => {
      list.push({
        id: el.id,
        name: el.id,
        label: el.typeValue,
      });
      return true;
    });
    return list;
  };

  const makeQualificationsData = (data) => {
    let list = [];
    data.map((el, index) => {
      list.push({
        id: el.id,
        name: el.typeValue,
      });
      return true;
    });
    return list;
  };

  const makeVerticalData = (data) => {
    let list = [];
    data.map((el, index) => {
      list.push({
        id: el.typeKey,
        name: el.typeValue,
      });
      return true;
    });
    return list;
  };

  const makeStatusData = (data) => {
    let list = [];
    data.map((el, index) => {
      list.push({
        id: el.typeKey,
        name: el.typeValue,
      });
      return true;
    });
    return list;
  };

  useEffect(async () => {
    setModalLoader(true);
    await Axios.get(`${urlLinks.baseUrl}${urlLinks.stateList}`).then(
      (response) => {
        setLeadState(makeLeadStateData(response.data.response.content));
      }
    );

    Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.leadSourceList}`).then(
      (response) => {
        setLeadSource(makeLeadSourceData(response.data.response));
      }
    );

    Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.genderList}`).then(
      (response) => {
        setGenderList(makeGenderData(response.data.response.content));
      }
    );

    Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.languagesList}`).then(
      (response) => {
        setPreferredLanguagesList(
          makePreferredLanguagesData(response.data.response.content)
        );
      }
    );

    Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.assetsList}`).then(
      (response) => {
        setAssetsList(makeAssetsData(response.data.response.content));
      }
    );

    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.workerUrls.qualificationList}`
    ).then((response) => {
      setQualificationsList(
        makeQualificationsData(response.data.response.content)
      );
    });

    Axios.get(`${urlLinks.baseUrl}${urlLinks.workerUrls.verticalList}`).then(
      (response) => {
        setVerticalList(makeVerticalData(response.data.response.content));
      }
    );

    setFilterRecruiterList([
      {
        id: -1,
        name: `Not Assigned`,
      },
      ...filterRecruiterList,
    ]);

    setStatusList(makeStatusData(searchList));
    setModalLoader(false);
  }, []);

  const loaderBar = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const output = modalLoader ? (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      {loaderBar}
    </Grid>
  ) : (
    <Grid
      container
      style={{ width: "520px", marginTop: "20px" }}
      className={`${styles.filterContainerDrawer}`}
    >
      <Grid container className={`${styles.FilterHeadingContainer}`}>
        <div>
          <Button
            onClick={handleCancelFilters}
            className={styles.filterCloseArrow}
          >
            <ArrowForwardIcon />
          </Button>
          <span className={styles.filterHeading}>Filters</span>
        </div>
        <div>
          <Button onClick={resetFilters} className={styles.resetFiltersButton}>
            Reset
          </Button>
        </div>
      </Grid>
      <div className={styles.filterHeadingDivider}></div>
      <Grid container className={styles.filtersContainer}>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Lead / Job State </span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="leadState"
            options={leadState}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Lead City</span>
          <SearchSelectFilter
            filters={filters}
            setFilters={setFilters}
            id="leadCity"
            sourceId={leadCityId}
            setSourceId={setLeadCityId}
            module="leadCityListing"
            multiple={true}
            placeholder="Search and select"
            url={`${urlLinks.baseUrl}${urlLinks.workerUrls.leadCities}`}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Lead Source</span>
          <MultipleSelectFilter
            filters={filters}
            setFilters={setFilters}
            id="leadSource"
            options={leadSource ? leadSource : ""}
            multipleFlag={true}
            sourceId={leadSourceId}
            setSourceId={setLeadSourceId}
          />
        </Grid>
        <Grid container className={`${styles.singleFilterContainer}`}>
          <Grid item xs={5}>
            <span className={styles.filterTitle}>Lead added on</span>
          </Grid>
          <Grid item xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="leadAddedOn"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>App installed</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="appInstalled"
            options={yesNo}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Status</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="status"
            options={statusList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Gender</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="gender"
            options={genderList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Preferred Pincodes</span>
          <SearchSelectFilter
            filters={filters}
            setFilters={setFilters}
            id="preferredPincodes"
            sourceId={preferredPincodes}
            setSourceId={setPreferredPincodes}
            module="preferredPincodesListing"
            multiple={true}
            placeholder="Search and select"
            url={`${urlLinks.baseUrl}${urlLinks.workerUrls.getAreaByPinCode}`}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Preferred Languages</span>
          <MultipleSelectFilter
            filters={filters}
            setFilters={setFilters}
            id="preferredLanguages"
            options={preferredLanguagesList ? preferredLanguagesList : ""}
            multipleFlag={true}
            sourceId={preferredLanguages}
            setSourceId={setPreferredLanguages}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Assets</span>
          <MultipleSelectFilter
            filters={filters}
            setFilters={setFilters}
            id="assets"
            options={assetsList ? assetsList : ""}
            multipleFlag={true}
            sourceId={assets}
            setSourceId={setAssets}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Qualifications</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="qualifications"
            options={qualificationsList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Vertical</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="vertical"
            options={verticalList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Documents uploaded</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="docUpload"
            options={yesNo}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Recruiter</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="recruiterId"
            options={filterRecruiterList}
          />
        </Grid>
        <Grid container className={`${styles.singleFilterContainer}`}>
          <Grid item xs={5}>
            <span className={styles.filterTitle}>Recruiter Assigned On</span>
          </Grid>
          <Grid item xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="recruiterAssignedBetween"
            />
          </Grid>
        </Grid>
        {unapplied && (
          <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
            <span className={styles.filterTitle}>Recruiter status</span>
            <AutoSelectFilter
              filters={filters}
              setFilters={setFilters}
              multipleFlag={false}
              id="recruiterStatus"
              options={recruiterStatusList}
            />
          </Grid>
        )}
      </Grid>
      <Grid container className={styles.filterButtonDividerContainer}>
        <div className={styles.filterHeadingDivider}></div>
        <Grid container style={{ marginTop: "30px" }} className="padding0">
          <div
            style={{
              paddingRight: "16px",
              paddingLeft: "300px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelFilters}
              size="medium"
              style={{ color: "#1C75BC", borderColor: "#1C75BC" }}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleApplyFilters}
            style={{ backgroundColor: "#1C75BC" }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return output;
};

export default FilterDrawer;
